<template>
  <b-container class="contact">
    <h1>{{ $t('global.contactMe') | capitalize }}</h1>
    <hr/>
    <b-row>
      <b-col class="contact-col" sm="12" lg="6">
        <h2>{{ $t('global.onSocialNetworks') | capitalize }}</h2>
        <ul class="list-unstyled contact-social-list">
          <li>
            <a target=_blank href="https://www.facebook.com/officialbako/">
              <FacebookIcon width="42" height="42"/>
            </a>
          </li>
          <li>
            <a target=_blank href="https://twitter.com/hperchec">
              <TwitterIcon width="42" height="42"/>
            </a>
          </li>
          <li>
            <a target=_blank href="https://github.com/hperchec">
              <GithubIcon width="42" height="42"/>
            </a>
          </li>
          <li>
            <a target=_blank href="https://www.linkedin.com/in/herveperchec/">
              <LinkedInIcon width="42" height="42"/>
            </a>
          </li>
          <li>
            <a target=_blank href="https://cults3d.com/fr/utilisateurs/herveperchec/">
              <Cults3DIcon width="42" height="42"/>
            </a>
          </li>
          <li>
            <a target=_blank href="https://www.thingiverse.com/BakoProd/about">
              <ThingiverseIcon width="42" height="42"/>
            </a>
          </li>
        </ul>
      </b-col>
      <b-col class="contact-col" sm="12" lg="6">
        <h2>...{{ $t('global.orMailMe') }}</h2>
        <p class="mail-text">✉ <a href="mailto:contact@herve-perchec.com">contact@herve-perchec.com</a></p><!-- eslint-disable-line vue-i18n/no-raw-text -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Contact',
  title: (context) => {
    return context.$options.filters.capitalize(context.$t('global.pages.contact')) + ' - ' + context.$t('global.titleSuffix')
  }
}
</script>
